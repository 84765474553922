import React from "react"

import Layout from "../components/layout"

const TermsPage = () => (
  <Layout>
    <div className="light-bg">
      <div
        className={"container light-bg py-5"}
        style={{ minHeight: "600px;" }}
      >
        <p align="center">
          <u>
            <strong>Deliciae T&amp;C</strong>
          </u>
        </p>
        <p>
          This website is operated by Deliciae. Throughout the site, the terms
          “we”, “us” and “our” refer to Deliciae. Deliciae offers this website,
          including all information, tools and services available from this site
          to you, the user, conditioned upon your acceptance of all terms,
          conditions, policies and notices stated here. These Terms of Service
          apply to all users of the site, including without limitation users who
          are browsers, vendors, customers, merchants, and/ or contributors of
          content.
        </p>
        <p>
          Please read these Terms of Service carefully before accessing or using
          our website. By accessing or using any part of the site, you agree to
          be bound by these Terms of Service.
        </p>
        <p>
          <strong>Products &amp; Services</strong>
        </p>
        <ul>
          <li>
            <p>Prices for our products are subject to change without notice.</p>
          </li>
          <li>
            <p>
              We have made every effort to display as accurately as possible the
              colors and images of our products that appear on the website. We
              cannot guarantee that your device’s display of any color will be
              accurate.
            </p>
          </li>
          <li>
            <p>
              Fondant Flower and any Fondant Figures are supported using Food Grade Cake decorating florist wires. Do not eat any fondant made figures and flowers. Please be careful with children.
            </p>
          </li>
          <li>
            <p>
              Each product is handmade &amp; hence, the final product may not
              match the image on the website to 100%.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to limit the sales of our products, offers,
              discounts, promotions or services to any person. We may exercise
              this right on a case-by-case basis.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to limit the quantities of any products or
              services that we offer.
            </p>
          </li>
          <li>
            <p>
              All descriptions of products or product pricing are subject to
              change at any time without notice, at the sole discretion of us.
            </p>
          </li>
          <li>
            <p>
              We follow strict hygiene protocol and operate under a
              manufacturing FSSAI license. We are not liable for any health
              issue claims arising from the consumption of any of our
              products.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to discontinue any product, promotion or
              offer at any time.
            </p>
          </li>
          <li>
            <p>
              We cannot guarantee product quality if they’re not stored as per
              storage instructions.
            </p>
          </li>
          <li>
            <p>
              Certain products or services may be available exclusively online
              through the website. These products or services may have limited
              quantities.
            </p>
          </li>
        </ul>
        <p>
          <strong>Refunds &amp; Replacement</strong>
        </p>
        <ul>
          <li>
            <p>
              Requests for replacements or refunds to orders should be made to
              <a href="mailto:care@deliciaecakes.com">
                <u>care@deliciaecakes.com</u>
              </a>
            </p>
          </li>
          <li>
            <p>
              Acceptance of requests for order refunds &amp; replacements is
              subject to discretion by us. Refunds &amp; Replacement must meet
              the company's policy on reasons, and this is at our discretion.
            </p>
          </li>
          <li>
            <p>
              Refunds will only be credited to the original source of payment
              &amp; will take 5 to 7 business days to process.
            </p>
          </li>
          <li>
            <p>
              If the products are damaged, incomplete, different from the one you have ordered, or have any other issue, please click a picture as soon as you receive them & contact us immediately and we will have them replaced, upon investigation of the case.
            </p>
          </li>
        </ul>
        <p>
          <strong>Cancellation &amp; Modification</strong>
        </p>
        <ul>
          <li>
            <p>
              Any requests to modify confirmed orders will be accepted only a
              minimum of 12 hours in advance.
            </p>
          </li>
          <li>
            <p>
              Any requests for order cancellations will be accepted only a
              minimum of 24 hours in advance.
            </p>
          </li>
          <li>
            <p>
              Acceptance of requests for order modifications &amp; cancellations
              is subject to discretion by us.
            </p>
          </li>
          <li>
            <p>
              A valid reason for cancellation is required. Any cancellation will
              attract a 10% processing fee. Else, you can choose the option of
              100% credit.
            </p>
          </li>
          <li>
            <p>
              In the event that we make a change to or cancel an order, we may
              attempt to notify you by contacting the e-mail and/or billing
              address/phone number provided at the time the order was made.
            </p>
          </li>
        </ul>
        <p>
          <strong>Ordering</strong>
        </p>
        <ul>
          <li>
            <p>
              You agree to provide current, complete and accurate purchase and
              account information for all purchases made at our store. You agree
              to promptly update your account and other information, including
              your email address and credit card numbers and expiration dates,
              so that we can complete your transactions and contact you as
              needed.
            </p>
          </li>
          <li>
            <p>
              All billing &amp; delivery information (first and last name,
              mobile number, email address, complete delivery address) must be
              accurate &amp; complete. This is necessary for delivery to take
              place successfully.
            </p>
          </li>
          <li>
            <p>
              All orders will be processed &amp; confirmed only after a 100%
              advance payment is made.
            </p>
          </li>
          <li>
            <p>
              GST &amp; delivery amounts will be calculated after your product
              is added to the cart.
            </p>
          </li>
          <li>
            <p>
              Delivery charges depend upon delivery areas &amp; minimum order
              amount.
            </p>
          </li>
          <li>
            <p>
              Minimum order amount to process your order, minimum order amount
              for free delivery &amp; delivery charges are subject to change.
            </p>
          </li>
          <li>
            <p>
              We may restrict orders and/or deliveries to certain areas and/or
              dates.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to refuse any order you place with us. We
              may, in our sole discretion, limit or cancel quantities purchased
              per person or per order.
            </p>
          </li>
        </ul>
        <p>
          <strong>Delivery</strong>
        </p>
        <ul>
          <li>
            <p>
              We are not liable for the condition of products that reach the
              customer through third party logistics/ apps or any other type of
              pick up arranged by the customer.
            </p>
          </li>
          <li>
            <p>
              All orders are committed to be delivered at any given point in
              time within the delivery time slot &amp; not at any specific
              delivery time. Cancelling an order due to a fixed committed time
              is not guaranteed.
            </p>
          </li>
          <li>
            <p>
              We are committed to delivering all our orders within the given
              time slot, on the given day. If by any chance an order is
              delivered outside of the committed time slot, it is not
              intentional, and at most would be liable to replace the cake or
              refund the amount.
            </p>
          </li>
          <li>
            <p>
              We are committed to delivering all our orders within the given
              time slot, on the given day. If by any chance an order is missed
              and not delivered, it is not intentional, and at most would be
              liable to replace the cake or refund the amount.
            </p>
          </li>
          <li>
            <p>
              If you happen to be unavailable when the delivery executive
              reaches your location, he will hand the order to your building’s
              security guard. Re-delivering our products is not possible due to
              their perishable nature.
            </p>
          </li>
        </ul>
        <p>
          <strong>General</strong>
        </p>
        <ul>
          <li>
            <p>
              We reserve the right to blacklist any customer that showcases
              disrespectful behavior, disregard or misconduct towards the
              company, its owners or employees.
            </p>
          </li>
          <li>
            <p>
              Occasionally there may be information on our website that contains
              inaccuracies or omissions that may relate to product descriptions,
              pricing, promotions, offers, delivery charges &amp; availability.
              We reserve the right to correct any errors, inaccuracies or
              omissions, and to change or update information or cancel orders if
              any information is inaccurate at any time without prior notice
              (including after you have submitted your order).
            </p>
          </li>
          <li>
            <p>
              Ownership of the products is transferred completely to the
              customer only once full payment of the billed amount is made, and
              the product is delivered. Products are at the risk of the
              customers once they have been delivered.
            </p>
          </li>
          <li>
            <p>
              You may not use our products &amp; services for any illegal or
              unauthorized purpose.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              We reserve the right to refuse service to anyone for any reason at
              any time.
            </p>
          </li>
          <li>
            <p>
              You agree not to reproduce, duplicate, copy, sell, resell or
              exploit any portion of the product &amp; service, use of the
              service, or access to the service or any contact on the website
              through which the service is provided, without express written
              permission by us.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              We shall not be liable to you for any modification, price change,
              suspension or discontinuance of the products &amp; services.
            </p>
          </li>
          <li>
            <p>
              We reserve the right to limit or prohibit orders that, in our sole
              judgment, appear to be placed by dealers, resellers or
              distributors.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default TermsPage
